import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: 'salesvalidation', loadChildren: () => import('./salesvalidation/salesvalidation.module').then(m => m.SalesvalidationModule) },
  { path: 'saleshistory', loadChildren: () => import('./saleshistory/saleshistory.module').then(m => m.SaleshistoryModule) },
  { path: 'maintenance', loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule) },
  { path: 'inventoryvalidation', loadChildren: () => import('./inventoryvalidation/inventoryvalidation.module').then(m => m.InventoryvalidationModule) },
  { path: 'inventoryhistory', loadChildren: () => import('./inventoryhistory/inventoryhistory.module').then(m => m.InventoryhistoryModule) },
  { path: 'current', loadChildren: () => import('./current/current.module').then(m => m.CurrentModule) },
  { path: 'history', loadChildren: () => import('./history/history.module').then(m => m.HistoryModule) },
  { path: 'recordDetails', loadChildren: () => import('./record-details/record-details.module').then(m => m.RecordDetailsModule) },
  { path: 'eBill', loadChildren: () => import('./e-bill/e-bill.module').then(m => m.EBillModule) },
  { path: 'undelete', loadChildren: () => import('./undelete/undelete.module').then(m => m.UndeleteModule) },
  { path: 'upload', loadChildren: () => import('./upload-file/upload-file.module').then(m => m.UploadFileModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }