import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthClient } from '../../OAuth';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AppState } from 'src/app/app.state';

export class Service {

  options: any;

  constructor(public http: HttpClient, public state: AppState) {
  }

  get(endpoint: string, opt?: any): Observable<any> {

    this.setDefaultOptions();

    if (this.checkTime()) {
      return this.http.get(endpoint, (opt ? opt : this.options));
    }
    else {
      this.showPop();
    }
  }

  post(endpoint: string, body: any, opt?: any): Observable<any> {

    this.setDefaultOptions();

    if (this.checkTime()) {
      return this.http.post(endpoint, body, (opt ? opt : this.options));
    }
    else {
      this.showPop();
    }
  }

  put(endpoint: string, body: any): Observable<any> {

    this.setDefaultOptions();

    if (this.checkTime()) {
      return this.http.put(endpoint, body, this.options);
    }
    else {
      this.showPop();
    }
  }

  delete(endpoint: string): Observable<any> {

    this.setDefaultOptions();

    if (this.checkTime()) {
      return this.http.delete(endpoint, this.options);
    }
    else {
      this.showPop();
    }
  }

  patch(endpoint: string, body: any): Observable<any> {

    this.setDefaultOptions();

    return this.http.patch(endpoint, body, this.options);
  }

  public handleError(error: any): Observable<any> {
    throw error.json().error ? error.json().error : 'Server error';
  }

  public checkTime(): boolean {
    let d1 = new Date();
    let current_time = d1.getTime();
    let compare_time = parseInt(sessionStorage.getItem('expiry_time'))
    if (compare_time! < current_time) {
      return false;
    }
    else {
      return true;
    }
  }

  showPop() {
    let oAuth = new OAuthClient();
    Swal.fire({
      title: 'Warning',
      text: 'Your token has expired. You will be redirected to Login page!',
      icon: 'warning',
      confirmButtonText: 'OK'
    }).then((res) => {
      if (res.value) {
        oAuth.authenticate()
        sessionStorage.clear();
      }
    });
  }

  setDefaultOptions() {
    this.options = { headers: new HttpHeaders({
      Authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
      IdToken: sessionStorage.getItem('id_token')
    })}
  }

}
