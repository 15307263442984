import { Injectable } from '@angular/core';
import { URL } from './URL';
import { Service } from './service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../../app.state';

@Injectable(
  { providedIn: 'root' }
)
export class ValidationService extends Service {

  constructor(public http: HttpClient, public state:AppState) {
    super(http,state);
  }

  validateAll(): Observable<any> {
    return this.post(URL.BASE_URL + `services/direct/Sales/Current/validate`, `recIds=`);
  }

  validateSingle(record): Observable<any> {
    let body = `recIds=`;
    console.log(record);
    const cnt = record.recIds.length;
    console.log(cnt);
    let cnt1 = 0;
    for (const i of record.recIds) {
      body += i;
      if (cnt1 !== cnt - 1) {
        body += ',';
      }
      cnt1 += 1;
    }
    return this.post(URL.BASE_URL + `services/direct/Sales/Current/validate`, body);
  }

  giveStatus(body) {
    return this.get(URL.BASE_URL + `/services/getTaskExecutionStatus?execRequestId=${body}`);
  }

  save(saveData, val): Observable<any> {
    if (val === 1) {
      const body = [];
      body.push(saveData);

      return this.put(URL.BASE_URL + `services/direct/Sales/Current`, body);
    }
    return this.put(URL.BASE_URL + `services/direct/Sales/Current`, saveData);
  }

  giveCount(): Observable<any> {
    return this.get(URL.BASE_URL + `services/direct/Sales/Current/Count`);
  }

}
