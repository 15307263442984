import { Injectable, Output, EventEmitter } from '@angular/core';

export interface InternalStateType {
  [key: string]: any;
}

@Injectable()
export class AppState {
  // tslint:disable-next-line
  _state: InternalStateType = {};
  @Output() stateChanged = new EventEmitter();
  @Output() breadcrumbsChanged = new EventEmitter();

  // already return a clone of the current state
  get state() {
    return this._state = this._clone(this._state);
  }

  // never allow mutation
  set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }


  get(prop?: any) {
    // use our state getter for the clone
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : null;
  }

  set(prop: string, value: any) {
    // internally mutate our state
    this._state[prop] = value;
    // tslint:disable-next-line
    this.stateChanged.emit({ prop: prop, value: value });
    return this._state;
  }

  changeBreadcrumbs(breadcrumbs) {
    this.breadcrumbsChanged.emit(breadcrumbs);
  }

  removeKey(key) {
    delete this._state[key];
  }

  private _clone(object: InternalStateType) {
    // simple object clone
    return JSON.parse(JSON.stringify(object));
  }
}
