// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: true,
//   BASE_URL: 'https://api.ebiz-dev.basf.com',
//   FEDERATION_AUTH_URL: 'https://federation-qa.basf.com/nidp/oauth/nam/authz',
//   OAUTH_CLIENT_ID: '4695dd30-ee1c-4f52-9bf8-0386534b30dd',
//   SCOPE: 'APN_eBiz-Groups',
//   EBIZ_ROLE: 'cn=APN-EBIZ-EBILL_EBIZ_DEV_USER,ou=SYNCGROUPS,ou=GLOBALGROUPS,o=AUTH',
//   EBILL_ROLE: 'cn=APN-EBIZ-EBILL_EBILL_DEV_USER,ou=SYNCGROUPS,ou=GLOBALGROUPS,o=AUTH',
//   OAUTH_REDIRECT_URI: 'https://ebiz-dev.basf.com/index.html'
// };

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  BASE_URL: 'https://api.ebiz-dev.basf.com',
  AZURE_AUTH_URL: 'https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55/oauth2/v2.0/authorize',
  OAUTH_CLIENT_ID: 'e43e0fa0-2e3b-4e37-b566-f54ce22ab1a5',
  SCOPE: 'openid profile offline_access',
  EBIZ_ROLE: 'APN-EBIZ-EBILL_EBIZ_DEV_USER',
  EBILL_ROLE: 'APN-EBIZ-EBILL_EBILL_DEV_USER',
  OAUTH_REDIRECT_URI: 'https://ebiz-dev.basf.com/index.html',
  ENV_URL:'https://ebiz-dev.basf.com/'
};
